<template>
  <div>
    <Header />
    <main v-if="singlePost || collection" class="pageContent">
        <div class="post">
          <h3 class="categoryChip">{{ collection?.toLocaleUpperCase() || singlePost?.collection.toLocaleUpperCase() }}</h3>
          <h1 class="postTitle">{{ title || singlePost.title }}</h1>
  
            <div class="principal-article" v-if="url || singlePost.url">
              <iframe :src="'https://front.highlightnow.com/player.html#/' + (id || singlePost.id)"
                      width="100%"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      sandbox="allow-scripts allow-same-origin allow-forms"
                      class="postVideo"></iframe>
            </div>
          <div v-else>
            <img :src="thumb || singlePost.thumb" alt="Thumbnail do Post" class="postThumbnail">
          </div>
  
          <p class="descriptionText">{{ description || singlePost.description }}</p>
          <p>{{ formatDate(date || singlePost.date) }}</p>
        </div>
  
        <aside v-if="loUltimoCollection">
          <h3 class="asidePostCategory">{{ loUltimoCollection?.collection?.toLocaleUpperCase() }}</h3>
  
          <div class="gridContainer">
            <div v-for="(item, index) in loUltimoCollection.items" :key="item.id">
              <router-link class="link" :to="{ name: 'PostDetails', params: {
                id: item.id,
                collection: loUltimoCollection.collection,
                title: item.title,
                description: item.description,
                profile: item.profile,
                date: item.date,
                thumb: item.thumb.toString(),
                url: item.url.toString(),
                adsEnabled: item.adsEnabled,
              } }">
                <div style="display: flex; justify-content: space-between; align-items: center">
                  <p class="postTitle">{{ item?.title }}</p>
                  <img :src="item.thumb?.toString()" class="postThumbnailDivRightVertical" />
                </div>
              </router-link>
  
              <div v-if="index !== 2" class="divider"></div>
            </div>
  
          </div>
        </aside>
    </main>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import collectionData from '../services/Directus.js';

export default {
  name: 'PostDetails',
  data() {
    return {
      directusData: [],
      loUltimoCollection: null,
      singlePost: null,
    }
  },
  components: {
    Header,
  },
  props: [
    'id',
    'collection',
    'title',
    'description',
    'profile',
    'date',
    'thumb',
    'adsEnabled',
    'url',
  ],
  async mounted() {
      try {
          if (this.id && (!this.collection || !this.loUltimoCollection)) {
              const foundItem = await this.getData(this.id);
              this.singlePost = foundItem;
          }
      } catch (error) {
          console.error("An error occurred in the mounted hook:", error);
      }
},
  methods: {
    formatDate(dateString) {
      const months = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio", 
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ];

      const date = new Date(dateString);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day} de ${month} de ${year}`;
    },
    async getData(postId) {
      const directusData = await collectionData();
      this.directusData = directusData
      this.loUltimoCollection = directusData.filter(item => item.collection === "Lo Ultimo")[0];

      const foundItem = this.findItemById(postId, directusData)
      return foundItem;
    },
    findItemById(id, data) {
      for (const collection of data) {
        for (const item of collection.items) {
          if (item.id === id) {
            item.collection = collection.collection
            return item;
          }
        }
      }
    }
  },
  watch: {
    singlePost() {
      console.log('singlePost >>>>>', this.singlePost);
    }
  }
}
</script>

<style>

.postVideo {
    width: 100%;
    aspect-ratio: 16 / 9; /* Adjust based on the content's aspect ratio */
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
}


.pageContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}

.post {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
  color: whitesmoke;
}

.descriptionText {
  width: 94%;
  margin-left: 3%
}

aside {
  width: 20%;
  margin-left: 10px;
  border: 1px solid #3C5A78;
}

.categoryChip {
  background-color: #edf3f8;
  border-radius: 20px;
  padding: 10px;
  width: fit-content;
  color: #3d5c7b;
}

.postThumbnail {
  width: 100%;
  aspect-ratio: 16 / 9; /* Adjust based on the content's aspect ratio */
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
}

@media (min-width: 600px) and (max-width: 1400px) {
  .parent {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  .div1 .postTitle {
    font-size: 22px
  }

  .divRight {
    width: 100%;
  }

  .post {
      width: 90%;
  }

  .directionColumn {
    width: 90%;
  }

  aside {
    display: none;
  }
}

@media (max-width: 600px) {
    .parent {
        display: flex;
        flex-direction: column;
        font-size: 16px;
    }

    .div1 .postTitle {
        font-size: 16px;
    }

    .postTitle {
        font-size: 16px;
    }

    .divRight {
        width: 100%;
    }

    .post {
        width: 90%;
    }

    .directionColumn {
        width: 90%;
    }

    aside {
        display: none;
    }
}

</style>