<template>
  <div>
    <Header />
  <main class="pageContent">
    <div class="directionColumn">

      <!-- principal container -->      
       <!-- <div style="width: 100%;" v-if="statusPrincipal">
        <div style="width: 100%; overflow: hidden;">
          <SwiperCarousel :principalCollection="principalCollection" />
        </div>
       </div> -->
      
      <!-- <div v-if="selectedCarouselVideo">Imagem selecionada: {{ selectedCarouselVideo?.id }}</div> -->
      <div class="gridContainer">
        <div class="principal-container" v-if="principalCollection?.items?.length > 0">
            <div class="principal-article">
                <router-link class="link" :to="{ name: 'PostDetails', params: {
                    id: principalCollection.items[0].id,
                    collection: principalCollection.collection,
                    title: principalCollection.items[0]?.title,
                    description: principalCollection.items[0]?.description,
                    profile: principalCollection.items[0]?.profile,
                    date: principalCollection.items[0]?.date,
                    thumb: principalCollection.items[0]?.thumb.toString(),
                    url: principalCollection.items[0]?.url.toString(),
                    adsEnabled: principalCollection.items[0]?.adsEnabled,
                  } }">

                <p class="postTitle">{{ selectedCarouselVideo?.title }}</p>
              </router-link>
                <div class="postThumbnail">
                  <iframe id="principal-container-video" name="principal-container-video" :src="'https://front.highlightnow.com/player.html#/' + selectedCarouselVideo?.id?.toString()" width="100%" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="postThumbnail" :key="iframeUpdate"></iframe>
                </div>
            </div>
            <div class="divider"></div>

            <div class="div-below">
              <SwiperCarousel :principalCollection="principalCollection" @imageClicked="handleImageClicked" />
              <!-- <div v-for="(directusItem) in principalCollection.items.slice(1, 5)" :key="directusItem.id" class="div-below-article">
                  <router-link class="link" :to="{ name: 'PostDetails', params: {
                      id: directusItem.id,
                      collection: principalCollection.collection,
                      title: directusItem.title,
                      description: directusItem.description,
                      profile: directusItem.profile,
                      date: directusItem.date,
                      thumb: directusItem.thumb.toString(),
                      url: directusItem.url.toString(),
                      adsEnabled: directusItem.adsEnabled,
                    } }">
  
                    <img :src="directusItem.thumb?.toString()" class="postThumbnailDivRight" />
                    <p class="postTitle">{{ directusItem.title }}</p>
                </router-link>
              </div> -->
            </div>
        </div>
      </div>

      <!--  posts container -->
      <div class="postsContainer" v-for="category in directusData" :key="category.collection">
        <h3 class="postCategory">{{ category.collection.toLocaleUpperCase() }}</h3>

        <div class="parent" v-if="category?.items?.length > 0">
            <div class="div1">
                <router-link class="link" :to="{ name: 'PostDetails', params: {
                    id: category.items[0].id,
                    collection: category.collection,
                    title: category.items[0]?.title,
                    description: category.items[0]?.description,
                    profile: category.items[0]?.profile,
                    date: category.items[0]?.date,
                    thumb: category.items[0]?.thumb.toString(),
                    url: category.items[0]?.url.toString(),
                    adsEnabled: category.items[0]?.adsEnabled,
                  } }">

                <img :src="category.items[0]?.thumb.toString()" class="postThumbnail" />
                <p class="postTitle">{{ category.items[0]?.title }}</p>
                </router-link>
            </div>

            <div v-for="(directusItem, index) in category.items.slice(1, 5)" :key="directusItem.id" class="divRight">
                <router-link class="link" :to="{ name: 'PostDetails', params: {
                    id: directusItem.id,
                    collection: category.collection,
                    title: directusItem.title,
                    description: directusItem.description,
                    profile: directusItem.profile,
                    date: directusItem.date,
                    thumb: directusItem.thumb.toString(),
                    url: directusItem.url.toString(),
                    adsEnabled: directusItem.adsEnabled,
                  } }">

                  <div style="display: flex; justify-content: space-between; align-items: center">
                      <p class="postTitle">{{ directusItem.title }}</p>
                      <img :src="directusItem.thumb?.toString()" class="postThumbnailDivRight" />
                  </div>
                </router-link>
                <div v-if="index !== 2" class="divider"></div>
            </div>
        </div>


      </div>
    </div>

    <!-- 'Lo ultimo' aside container -->
    <aside>
      <h3 class="asidePostCategory">{{ loUltimoCollection?.collection?.toLocaleUpperCase() }}</h3>

      <div class="gridContainer">
        <div v-for="(item, index) in loUltimoCollection.items" :key="item.id">
          <router-link class="link" :to="{ name: 'PostDetails', params: {
            id: item.id,
            collection: loUltimoCollection.collection,
            title: item.title,
            description: item.description,
            profile: item.profile,
            date: item.date,
            thumb: item.thumb.toString(),
            url: item.url.toString(),
            adsEnabled: item.adsEnabled,
          } }">
            <div style="display: flex; justify-content: space-between; align-items: center">
              <p class="postTitle">{{ item?.title }}</p>
              <img :src="item.thumb?.toString()" class="postThumbnailDivRightVertical" />
            </div>
          </router-link>

          <div v-if="index !== 2" class="divider"></div>
        </div>
      </div>
    </aside>

  </main>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import collectionData from '../services/Directus.js';
import SwiperCarouselVue from '../components/SwiperCarousel.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data() {
    return {
      directusData: [],
      loUltimoCollection: {},
      principalCollection: {},
      selectedCarouselVideo: null,
      statusPrincipal: false,
      iframeUpdate: false,
    };
  },
  components: {
    Header,
    SwiperCarousel: SwiperCarouselVue,
  },
  methods: {
    async getData() {
      const directusData = await collectionData();
      this.directusData = directusData.filter(item => item.collection !== "Lo Ultimo" && item.collection !== "Principal");
      this.loUltimoCollection = directusData.filter(item => item.collection === "Lo Ultimo")[0];
      this.principalCollection = directusData.filter(item => item.collection === "Principal")[0];
    
      if (this.principalCollection?.items?.length > 0) {
        return this.selectedCarouselVideo = this.principalCollection.items[0];
      }
    },
    handleImageClicked(postItem) {
      this.selectedCarouselVideo = postItem;
      this.iframeUpdate = !this.iframeUpdate;
    },
  },
  async mounted() {
    this.getData();
  },
  watch: {
    principalCollection() {
      // console.log('this.principalCollection >>>>>>>', this.principalCollection)

      if (this.principalCollection?.items?.length > 0) {
        return this.statusPrincipal = true;
      }
    }
  },
}
</script>

<style>
body {
  font-family: Source Sans Pro,sans-serif;
    background-color: #181a1b;
}

/* .principal-container {
  display: flex;
  flex-direction: column;
  border: 3px solid red;
} */

.swiper {
  width: 50%;
  height: auto;
  overflow: visible;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.teste {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 0;
}

.principal-frame-container {
  width: 100%;
  height: 100%;
}

.principal-frame-container > iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
}

.principal-article {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Change main video Size */
  /* max-width: 800px;
  max-height: 800px; */
  aspect-ratio: 16 / 9;
}

.principal-article > img {
  width: 100%;
  height: 100% ;
}

.principal-article > a > p {
  //font-size: 2rem;
  font-weight: 700;
  font-family: Source Sans Pro,sans-serif;
}

.pageContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 0px;
}

.directionColumn {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.post {
  margin-bottom: 5vh;
}

/* .firstPost {
  color: red;
  font-weight: 700;
  font-size: xx-large;
} */

/* .firstPost .postTitle {
    font-weight: bold;
    font-size: large;
  } */

.divider {
  width: 100%;
  height: 1px;
  background-color: #303030;
  margin: 20px auto;
}

aside {
  width: 25% !important;
  margin-left: 10px;
  padding-left: 15px;
  border: none !important;
}

.asidePostCategory {
  background-color: #B20837;
  border-radius: 20px;
  padding: 7px 15px;
  color: #FFFFFF;
}

.video {
  width: 'fit-content';
  height: 100px;
  border: 1px solid black;
}

.postCategory {
  background-color: #3C5A78;
  border-radius: 20px;
  padding: 7px 15px;
  color: #FFFFFF;
}

.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-size: 18px;
  font-family: sans-serif;
}

.link {
  text-decoration: none;
  color: inherit;
}

.postThumbnail {
  width: 100%;
  height: auto;
  border: none;
  border-radius: 10px;
}

.postThumbnailDivRight {
    width: 50%;
    height: auto;
    //max-width: 120px;
    border: none;
    border-radius: 10px;
    margin: 4px;
}

.postThumbnailDivRightVertical {
    width: 180px;
    height: auto;
    border: none;
    border-radius: 10px;
    margin: 6px;
}

.postTitle {
  padding: 0 10px;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.div1 .postTitle {
  font-size: 28px;
  font-weight: bold;
}

.div1 {
  grid-area: 1 / 1 / 5 / 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
}

.div1:hover {
  color: #f43c75; 
}

.divRight {
  grid-area: span 1 / span 1;
}

.divRight:hover {
  color: #f43c75; 
}

.div-below {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .div-below-article {
        flex-basis: 45%; /* Allow two items per row on smaller screens */
        max-width: 45%; /* Adjust this value to fit your design on smaller screens */
    }
}

@media (max-width: 480px) {
    .div-below-article {
        flex-basis: 90%; /* Stack items vertically on very small screens */
        max-width: 90%;
    }
}

/* div.swiper-button-next {
  border: 1px solid red;
  position: absolute;
  right: 0;
}

div.swiper-button-prev {
  border: 1px solid red;
  position: absolute;
  left: 0;
} 

/* .div-below-article {
    flex: 1 1 18%;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-width: 90%;
} */

/* div.swiper-button-next {
  border: 1px solid red;
  position: absolute;
  right: 0;
>>>>>>> 36178a9 (Style: adjust carousel slides height and text)
}

div.swiper-button-prev {
  border: 1px solid red;
  position: absolute;
  left: 0;
} */

.router-link-no-style {
  text-decoration: none;
  color: inherit;
}

.gridContainer {
  //display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 20px;
}

.principalFirstItem {
  grid-column: 1 / -1; /* Item em destaque ocupa toda a largura */
}

.principalFirstItem + .gridItem {
  grid-row: 2; /* Itens seguintes na próxima linha */
}

@media (min-width: 1400px) {
    .principal-article .postTitle {
        font-size: 28px;
    }
}

@media (min-width: 600px) and (max-width: 1400px) {
  .parent {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  .div1 .postTitle {
    font-size: 22px;
  }

  .postTitle {
    font-size: 22px;
  }

  .divRight {
    width: 100%;
  }

  .directionColumn {
    width: 80%;
  }

  aside {
    display: none;
  }
}

@media (max-width: 600px) {
    .parent {
        display: flex;
        flex-direction: column;
        font-size: 16px;
    }

    .div1 .postTitle {
        font-size: 16px;
    }

    .postTitle {
        font-size: 16px;
    }

    .divRight {
        width: 100%;
    }

    .directionColumn {
        width: 80%;
    }

    aside {
        display: none;
    }
}

</style>
