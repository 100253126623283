<template>
    <header>
        <div class="imagesContainer">
            <router-link to="/">
                <img class="logo" :src="require('../../src/assets/images/highlight_logo.webp')" alt="logo"/>
            </router-link>
        </div>
        <div class="buttonsContainer">
            <input class="searchInput" type="text" placeholder=" 🔎 Buscar" />
            <button class="navButton">ECONOMÍA</button>
            <button class="navButton">POLÍTICA</button>
            <button class="navButton">POLICIALES</button>
            <button class="navButton">DEPORTES</button>
            <button class="navButton">MÁS</button>
        </div>
        <button class="menuSideBar">MÁS</button>
    </header>
</template>

<script>
export default {
    name: 'Header'
}
</script>


<style>

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .navButton {
    text-decoration: none;
    background: none;
    border: 0;
    color: white;
  }

  .navButton:hover {
    color: #f43c75; 
  }

  /* .navButtonLive {
    background-color: #f43c75;
    padding: 8px 12px;
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 60px;
    border: 0;
  }

  .navButtonLive:hover {
    opacity: 0.7
  } */

  .logo {
      max-height: 100px;
      width: auto;
      margin-top: 10px;
  }
  
  .imagesContainer {
    display: flex;
    align-items: center;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .searchInput {
    border-radius: 20px;
    border: 1px solid black;
    padding-left: 20px;
    padding: 8px;
  }

  .menuSideBar {
    border: 0;
    background-color: #3d5c7b;
    padding: 8px;
    border-radius: 12px 0px 0px 12px;
    color: #FFFFFF;
    font-weight: 700;
  }

  
  @media screen and (min-width: 1400px) {
    .menuSideBar {
      display: none;
    }
  }

/* tela tem menos de 1400px de largura */
  @media screen and (min-width: 0px) and (max-width: 1399px) {
    .buttonsContainer {
      display: none;
    }

    .image2 {
      display: none;
    }

    .menuSideBar {
      display: flex;
      justify-content: end;
    }
  }
</style>
