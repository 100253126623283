<template>
  <swiper :navigation="true" :initialSlide="2" :loop="true" :slidesPerView="1.2" :centeredSlides="true" :spaceBetween="30" :modules="modules" class="mySwiper">
      <swiper-slide v-for="(directusItem) in principalCollection.items.slice(0, 5)" :key="directusItem.id" >
        <div class="link" @click="handleImageClick(directusItem)">
          <img :src="directusItem.thumb?.toString()" />
          <p class="videoTitle">{{ directusItem.title }}</p>
        </div>
      </swiper-slide>
  </swiper>
</template>
<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import { Navigation } from 'swiper/modules';

  export default {
    name: 'SwiperCarouselVue',
    props: {
      principalCollection: {
        type: Array,
        required: true
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Navigation],
      };
    },
    methods: {
      handleImageClick(directusItem) {
        this.$emit('imageClicked', directusItem);
      }
    },
  };
</script>

<style>
.mySwiper {
  width: 100%;
  height: 100%;
}

.carousel-image {
  margin-bottom: 40px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.swiper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  color: whitesmoke;
  background: #181a1b;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media (min-width: 400px) and (max-width: 1400px) {
    .swiper-slide {
        text-align: center;
        font-size: 14px;
        color: whitesmoke;
        background: #181a1b;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media (max-width: 400px) {
    .swiper-slide {
        text-align: center;
        font-size: 11px;
        color: whitesmoke;
        background: #181a1b;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}


.swiper-slide img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.videoTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  //width: 400px;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:hover {
  color: #f43c75; 
}
</style>