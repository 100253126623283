import { createDirectus, rest, readItems } from '@directus/sdk';

const collectionsData = async (directusData) => {
  const directus = createDirectus('https://www.cms.highlightnow.com').with(rest());

  const data = await directus.request(readItems('WEB_C9', {
    fields:  ["*.*.*"],
  }));

  directusData = data.map(item => ({
    collection: item.Name,
    items: item.Entries.map(entry => ({
      id: entry.Clips_id.id,
      description: entry.Clips_id.description,
      profile: entry.Clips_id.profile,
      clip: entry.Clips_id.clip,
      image: entry.Clips_id.image,
      date: entry.Clips_id.date,
      url: entry.Clips_id.url,
      server: entry.Clips_id.server,
      tag: entry.Clips_id.tag,
      title: entry.Clips_id.title,
      thumb: entry.Clips_id.thumb,
      premium: entry.Clips_id.premium,
      adsEnabled: entry.Clips_id.adsEnabled,
      adsUrl: entry.Clips_id.adsUrl
    }))
  }));

  return directusData;
};

export default collectionsData;
